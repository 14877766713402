<template>
  <v-img width="40%" class="mx-auto ma-4" contain :src="require('../assets/logo.png')" />
</template>

<script>
export default {
  name: 'Cabecera',

  data: () => ({
    //
  })
}
</script>
